import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
import { injectIntl } from "gatsby-plugin-intl";

import { Banner03DataSource, CMND01DataSource } from "../../data/media.data";
import { ContactFormDataSource } from "../../data/home.data";
import loadable from "@loadable/component";

const Layout = loadable(() => import("../../components/Layout"));
const SEO = loadable(() => import("../../components/SEO"));
const Banner = loadable(() => import("../../components/Banner"));

const ContactForm = loadable(() => import("../../components/Home/ContactForm"));
const CMND01 = loadable(() => import("../../components/CMND/CMND01"));

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

class OCRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    const { intl } = this.props;
    return (
      <>
        <SEO title={intl.formatMessage({ id: "seo.title_page33" })} pathname="/solutions/media-publishing" />
        <Layout>
          <Banner
            id="Banner0_3"
            key="Banner0_3"
            dataSource={Banner03DataSource}
            isMobile={this.state.isMobile}
          />
          {/* <Customers
            id="Customers"
            key="Customers"
            dataSource={CustomersDataSource}
            isMobile={this.state.isMobile}
          /> */}
          <CMND01
            id="CMND01"
            key="CMND01"
            dataSource={CMND01DataSource}
            isMobile={this.state.isMobile}
          />
          <ContactForm
            id="ContactForm"
            key="ContactForm"
            dataSource={ContactFormDataSource}
            isMobile={this.state.isMobile}
          />
          {/* <FAQ
            id="FAQ"
            key="FAQ"
            dataSource={FAQDataSource}
            isMobile={this.state.isMobile}
          /> */}
        </Layout>
      </>
    );
  }
}

export default injectIntl(OCRPage);
